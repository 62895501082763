import { template as template_d8ee364df4e643168ad2c8f69f21108a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d8ee364df4e643168ad2c8f69f21108a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
